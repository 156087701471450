





































import Vue from 'vue'
import Calendar from '@/assets/icons/calendar.svg';
import DropDrown from '@/assets/icons/dropdown.svg';
import VButton from './VButton.vue';
import { DateTime } from 'luxon';
export default Vue.extend({
  props: {
    loading:{
      type: Boolean,
      default: false
    },
    closeOnSelected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Calendar,
    DropDrown,
    VButton
  },

  data(){
    return {
      filterBy: 'Tout le temps',
      open: false,
      rangeDate: {
        start: '',
        end: ''
      },

      modelConfig: {
      type: 'string',
      mask: 'iso',
      start: {
        type: 'string',
        mask: 'iso',
        timeAdjust: '00:00:00',
      },
      end: {
        type: 'string',
        mask: 'iso',
        timeAdjust: '23:59:59',
      },
    },
    }
  },

  methods: {
    toggleDropDown(this: any){
      this.toggleMobileCalendar()
      
      this.$data.open = ! this.$data.open ;

      return this.$data.open
    },

    close(this: any){
      this.$emit('filtered:reset')
      this.rangeDate = {start: '', end: ''}
      this.filterBy = 'Tout le temps'
      this.toggleDropDown()
    },

    applyDateChange(this: any, rangeDate: any) {
      //
      this.$emit('filtered', {
        key: 'range',
        start: DateTime.fromJSDate(new Date(rangeDate.start)).startOf('day').toISO(),
        end: DateTime.fromJSDate(new Date(rangeDate.end)).endOf('day').toISO()
        })
      
      if (this.closeOnSelected) {
        
        this.toggleDropDown()
      }
      this.filterBy = 'Périodes'
    },

    filteredByDay(){
      const d = new Date();
      const date = new Date(d.getFullYear(), d.getMonth())
      console.log('today ', date.toLocaleString());
      return {
        key: 'day',
        start: DateTime.fromJSDate(date).startOf('day').toISO(),
        end: DateTime.fromJSDate(date).endOf('day').toISO()
      }
    },

    filteredByWeek(){
      // 
      const d =  new Date();
      d.setDate(d.getDate() - 7)
      const start = new Date(d)
      start.setDate(d.getDate() - d.getDay())
      const end = new Date(start)
      end.setDate(start.getDate() + 6)
      return {
        key: 'lastweek',
        start: DateTime.fromJSDate(start).startOf('day').toISO(),
        end: DateTime.fromJSDate(end).endOf('day').toISO()
        }
    },

    filteredByMounth(){
      // 
      const d = new Date();
      d.setMonth(d.getMonth() - 1);

      return {
        key: 'lastmonth',
        start: DateTime.fromJSDate(d).startOf('month').toISO(),
        end: DateTime.fromJSDate(d).endOf('month').toISO()
      }
    },

    filteredByYear(){
      const d = new Date();
      d.setFullYear( d.getFullYear() - 1);
      return {
        key: 'lastyear',
        start: DateTime.fromJSDate(d).startOf('year').toISO(),
        end: DateTime.fromJSDate(d).endOf('year').toISO()
      }
    },

    filterPeriodBy(this: any,filter: any){
      // 
      switch (filter.key) {
        case 'today':
          this.filterBy = filter.name
          this.$emit('filtered', this.filteredByDay())
          break;
        case 'lastweek':
          this.filterBy = filter.name
          this.$emit('filtered', this.filteredByWeek())
          break;
        case 'lastmonth':
          this.filterBy = filter.name
          this.$emit('filtered', this.filteredByMounth())
          break;
        case 'lastyear': 
          this.filterBy = filter.name
          this.$emit('filtered', this.filteredByYear())
      }

      if (this.closeOnSelected) {
        
        this.toggleDropDown()
      }
      
    },


    chooseDate(this: any){
      // 
      this.toggleMobileCalendar()
    },

    toggleMobileCalendar(){
      const wapper = this.$el.getElementsByClassName('my-calendar')[0];
      if (wapper) {
        wapper.classList.toggle('hidden')
        // if (wapper.classList.contains('hidden')) {
        //   wapper.classList.add('absolute')
        // }else {
        //   wapper.classList.remove('absolute')
        // }
      }
    }
  },

  computed: {
    inputStyles(){
      const style = {
        width: `${this.$data.filterBy.length}ch;`
      }
      return style;
    },

    filterList(){
      return [
        {name: "Aujourd'hui", key: 'today'},
        {name: 'Semaine passée', key: 'lastweek'},
        {name: 'Mois passé', key: 'lastmonth'},
        {name: 'Année passée', key: 'lastyear'},
      ]
    },

    theme() {
      return {
        arrows:"text-black vc-rounded vc-border-2 vc-border-transparent hover:vc-opacity-50 hover:vc-bg-gray-300 focus:vc-border-gray-300",
        bgAccentHigh:"bg-tex-2",
        bgAccentLow:"bg-white",
        bgLow:"bg-tex-2 vc-border-2 vc-border-tex-2",
        color:"red",
        container:"text-black text-base font-normal vc-bg-white",
        contentAccent:"text-black",
        contentAccentContrast:"vc-text-white",
        dayContent:"text-black",
        dayContentDisabled:"vc-text-gray-400",
        header:"text-black",
        weekdays: "text-black",
        highlightBaseFillMode:"light",
        highlightBaseClass:"",
        highlightBaseContentClass: "bg-tex-9",
        highlightStartEndClass:"",
        highlightStartEndFillMode:"solid",
        highlightStartEndContentClass: "bg-tex-2",
        headerTitle: "text-black"

      }
    },
  }
})
