











































































































































import DateFilter from '@/components/DateFilter.vue'
import VCard from '@/components/VCard.vue'
import Bill from '@/assets/icons/bill.svg'
import UserCircle from '@/assets/icons/user-circle.svg'
import File from '@/assets/icons/file.svg'
import Vue from 'vue'
import { IStat } from '@/interfaces'
import { socket } from '@/plugins'
import VInput from '@/components/VInput/VInput.vue'
import { mapState } from 'vuex'
export default Vue.extend({
  components: { 
    DateFilter,
    VCard,
    Bill,
    UserCircle,
    File,
    VInput
  },

  data(){
    return {
      filterLoading: false,
      showModal: false,
      newAlertAmount: 0,
      loading: false,
      modalTitle: 'Définir un solde d’alerte ',
      transations: [
        {name: 'totalRecharge', icon: 'Bill', description: 'Total des recharges', number: 0, isCurrency: true},
        {name: 'numberOfRecharge', icon: 'Bill', description: 'Nombre de recharges', number: 0, isCurrency: false},
        {name: 'totalKkiapayFees', icon: 'Bill', description: 'Total des commissions', number: 0, isCurrency: true},
        {name: 'transacRechargeAvg', icon: 'Bill', description: 'Valeur Moyenne des transactions', number: 0, isCurrency: true},
      ],

      clients: [
        {name: 'allUsersNumber', icon: 'UserCircle', description: 'Nombre d’inscrits', number: 0, isCurrency: false},
        {name: 'NumbOfUserWhoMakeOneTransac', icon: 'UserCircle', description: 'Clients ayant transacté 1 fois', number: 0, isCurrency: false},
        {name: 'subscribeByEmail', icon: 'UserCircle', description: 'Comptes crées par mail', number: 0, isCurrency: false},
        {name: 'subscribeByPhone', icon: 'UserCircle', description: 'Comptes crées par SMS', number: 0, isCurrency: false},
      ],

      // others: [
      //   {icon: 'File', description: 'Taux d’échec', number: '13%', isCurrency: false},
      //   {icon: 'File', description: 'Nbre moyen de transactions/jours', number: '5 000', isCurrency: true},
      //   {icon: 'File', description: 'Nbre moyen de transactions/mois', number: '24', isCurrency: false},
      //   {icon: 'File', description: 'Nbre moyen de transactions/an', number: '850 ', isCurrency: false},
      // ],
    }
  },

  methods: {
    async fetchStats(params = {}){
      let stat: any;
      try {
        stat = await this.$store.dispatch('getAllStat', params);

        this.transations.forEach((item) => {
          if (stat[item.name] !== null) {
            item.number = item.isCurrency ?  parseInt(stat[item.name]).toLocaleString() : stat[item.name]
            }else{
              item.number = 0;
            }
        })

        this.clients.forEach(item => {
          item.number = stat[item.name]
        })
        return Promise.resolve(stat)
      } catch (error) {
        console.log('stats error --> ', error);
        return Promise.reject(error)
      }
    },

    onFilteredDate(event: any) {
      console.log('event', event);
      if (event.key === 'range') {
        this.filterLoading = true;
      }
      this.fetchStats({startDate: event.start, endDate: event.end})
      .finally(() => {
        if (event.key === 'range') {
          this.filterLoading = false;
        }
      })
    },

    onFilteredReset(){
      this.fetchStats()
    },

    onSetAlertAmountClick() {
      if (this.hasAlertAmount()) {
        this.modalTitle = 'Modifier le solde d’alerte'
      }
      this.showModal = true
    },

    onSubmitAlert() {
      
      this.loading = true
      this.$store.dispatch('setAlertAmount', this.newAlertAmount)
      .then(() => {
        this.showModal = false
      })
      .finally(() => {
        this.loading = false
      })

      
    },

    hasAlertAmount(){
      return this.alertAmount > 0
    },

    formatAmount(amount: number) {
      let formatedAmount: any = amount
      if (amount > 0) {
        formatedAmount = amount.toLocaleString()
      }
      return formatedAmount
    }

  },

  computed: {
    ...mapState(['balance','alertAmount']),
    
  },

  async created (){
    await this.fetchStats()
  },

  mounted(){
    console.log('socket', socket);
    socket.on('connection',(sock) => {
      console.log('sock ', sock);
    })
    this.$store.dispatch('getBalance')
    this.$store.dispatch('getAlertAmount').then(() => {
      if (this.hasAlertAmount()) {
        this.newAlertAmount = this.alertAmount
      }
    })
  }
  
})
